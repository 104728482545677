import React from 'react';

const Testimonial = () => (
<section className="testimonial-section testimonial-border">
  <div className="container">
  <div class="sec-title-two text-center">
            <h1>Testimonials</h1>
        </div>
    <div className="row">
      {/*Start single testimonial item*/}
      <div className="col-md-4">
        <div className="single-testimonial-item">
          <div className="text-holder">
            <p>Solid Solid Place for all your Wireless and Computer needs! helped me with a new Macbook and Fixed an apple watch for me! Incredible and outstanding with customer service!!</p>
          </div> 
          <div className="client-info">
            {/* <div className="img-box">
              <img src="images/testimonial/tes-1.jpg" alt="Awesome Image" />
            </div> */}
            <div className="title-box">
              <h4>Dylan Oliveira</h4>
              {/* <p>Newyork</p> */}
            </div> 
          </div>
        </div>
      </div>
      {/*End single testimonial item*/}
      {/*Start single testimonial item*/}
      <div className="col-md-4">
        <div className="single-testimonial-item">
          <div className="text-holder">
            <p>Had my phone screen replaced here recently. Was very impressed with everything! Fantastic job, fast and friendly service. Will use again if needed and will definitely recommend to friends! Thank You!</p>
          </div> 
          <div className="client-info">
            {/* <div className="img-box">
              <img src="images/testimonial/tes-2.jpg" alt="Awesome Image" />
            </div> */}
            <div className="title-box">
              <h4>J A</h4>
              {/* <p>Dream Theme </p> */}
            </div> 
          </div>
        </div>
      </div>
      {/*End single testimonial item*/}  
      {/*Start single testimonial item*/}
      <div className="col-md-4">
        <div className="single-testimonial-item">
          <div className="text-holder">
            <p>Cell Tech for Less amazing customer service, amazing people, amazing prices and products. Stand behind them an wouldn’t go anywhere else. Thank you Waleed and team for being awesome!</p>
          </div> 
          <div className="client-info">
            {/* <div className="img-box">
              <img src="images/testimonial/tes-3.jpg" alt="Awesome Image" />
            </div> */}
            <div className="title-box">
              <h4>Roy Sears</h4>
              {/* <p>Green Stud</p> */}
            </div> 
          </div>
        </div>
      </div>
      {/*End single testimonial item*/}
    </div>
  </div>
</section>

)
export default Testimonial;